import { IButtonMenu, IDomEditor } from '@wangeditor/editor'

class MyButtonMenu {
  constructor() {
    this.title = '素材库上传' // 自定义菜单标题
    this.svg = this.tag = 'button'
  }

  getValue(editor) {
    return ' hello '
  }

  isActive(editor) {
    return false
  }

  isDisabled(editor) {
    return false
  }

  // 点击菜单时触发的函数
  exec(editor, value) {
    if (this.isDisabled(editor)) return
    console.log(editor, value)
    editor.emit('uploadAttachment')
  }
}

export default MyButtonMenu
